import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {
  calculate_companyValue,
  validateLettersAndNumbersOnly,
} from "./engine";
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import { lime } from "@material-ui/core/colors";
import NumerologyPaper from './NumerologyPaper';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: 0,
    width: 300,
  },
  button: {
    margin: theme.spacing(1),
  },
  root: {
    margin: 'auto',
  },
  paper: {
    padding: theme.spacing(3, 2),
    margin: 10,
  },
  chip: {
    margin: theme.spacing(1),
    backgroundColor: lime[700],
  },
}));

export default () => {
  const classes = useStyles();
  const [values, setValues] = useState({
    fullName: '',
  });
  const [errors, setErrors] = useState({});
  const [calculatedValues, setCalculatedValues] = useState('');

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
    setErrors({
      ...errors,
      [name]: false,
    });
  };
  const onReset = () => {
    setValues({
      fullName: '',
    });
    setErrors({});
    setCalculatedValues('');
  };

  const onSubmit = () => {
    const err = {};
    if (!values.fullName || !values.fullName.trim() || !validateLettersAndNumbersOnly(values.fullName)) {
      err.fullName = true;
    }
    if (Object.keys(err).length === 0) {
      const value = calculate_companyValue(values.fullName);
      setCalculatedValues(value);
    }
    setErrors(err);
  };

  return (
    <div className={classes.root}>
      <form className={classes.container} noValidate autoComplete="off">
        <TextField
          id="fullName"
          label="Full company name"
          className={classes.textField}
          margin="normal"
          value={values.fullName}
          onChange={handleChange('fullName')}
          required
          variant="outlined"
          error={errors['fullName']}
        />
        <div>
          <Button
            variant="contained"
            className={classes.button}
            onClick={onReset}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={onSubmit}
          >
            Calculate
          </Button>
        </div>
      </form>
      {calculatedValues && (
        <Paper className={classes.paper}>
          <Chip
            avatar={<Avatar>{calculatedValues}</Avatar>}
            label="Score"
            className={classes.chip}
          />
        </Paper>
      )}
      {calculatedValues && <NumerologyPaper
        title="Recommended"
        data={[{
          label: 'Extreme results',
          value: 22,
        }, {
          label: 'Best results',
          value: 8,
        }, {
          label: 'To be leader',
          value: 1,
        }]}
      />}
    </div>
  );
};
