export const validateLettersOnly = (value) => {
    return !value || value.length <= 0 || value.match(/^[a-zA-Z\s]*$/) !== null;
}

export const validateLettersAndNumbersOnly = (value) => {
    return !value || value.length <= 0 || value.match(/^[a-zA-Z0-9\s]*$/) !== null;
}

export const validateDOB = (value) => {
    return !value || value.length <= 0 || value.match(/[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]/) !== null;
}

const reduce = (number) => {
	if (parseInt("" + number) === 22 || parseInt("" + number) === 11) return parseInt("" + number);
	const numStr = "" + number;
	let total = 0;
	numStr.split('').forEach((eachDigit) => {
		total += parseInt(eachDigit);
	});
	if (("" + total).length > 1 && total !== 22 && total !== 11) {
		return reduce(total);
	}
	else return total;
}

export const calculate_LifePath = (dateOfBirth) => {
	return reduce (dateOfBirth.replace(/-/g, ''));
}

export const calculate_Expression = (fullName) => {
  const referenceMap = {
    'A' : 1, 'B' : 2, 'C' : 3, 'D' : 4, 'E' : 5, 'F' : 6, 'G' : 7, 'H' : 8, 'I' : 9,
    'J' : 1, 'K' : 2, 'L' : 3, 'M' : 4, 'N' : 5, 'O' : 6, 'P' : 7, 'Q' : 8, 'R' : 9, 
    'S' : 1, 'T' : 2, 'U' : 3, 'V' : 4, 'W' : 5, 'X' : 6, 'Y' : 7, 'Z' : 8
  };
	let total = 0;
	fullName.replace(/ /g, '').split('').forEach((each) => {
		total += referenceMap[each.toUpperCase()];
	});
	return reduce(total);
}

export const calculate_SoulUrge = (fullName) => {
	const referenceMap = {
		'A' : 1, 'E' : 5, 'I' : 9, 'O' : 6, 'U' : 3
	};
	let total = 0;
	fullName.replace(/ /g, '').split('').forEach(function(each) {
		if (referenceMap[each.toUpperCase()]) {
			total += referenceMap[each.toUpperCase()];
		}
	});
	return reduce(total);
}

export const calculate_BirthDate = (dateOfBirth) => {
	return reduce(parseInt(dateOfBirth.split('-')[1]));
}

export const calculate_Maturity = (lifePath, expression) => {
	return reduce(lifePath + expression);
}

export const calculate_Growth = (firstName) => {
	return calculate_Expression(firstName);
}

export const calculate_Challenge = (dateOfBirth) => {
	const date = parseInt(dateOfBirth.split('-')[1]);
	const month = parseInt(dateOfBirth.split('-')[0]);
	const year = parseInt(dateOfBirth.split('-')[2]);
	const x = Math.abs(reduce(month) - reduce(date));
	const y = Math.abs(reduce(date) - reduce(year));
	return reduce(Math.abs(x - y));
}

export const calculate_Temperament = (fullName) => {
	fullName = fullName.toUpperCase();
	const emotionalList 	= ['B', 'I', 'O', 'R', 'S', 'T', 'X', 'Z'];
	const physicalList 	= ['D', 'E', 'M', 'W'];
	const intuitionList 	= ['C', 'F', 'K', 'Q', 'U', 'V', 'Y'];
	const mentalList 		= ['A', 'G', 'H', 'J', 'L', 'N', 'P'];

	let emotional = 0, physical = 0, intuition = 0, mental = 0;

	emotionalList.forEach(function(each) {
		emotional += (fullName.match(new RegExp(each, "g")) || []).length;
	});
	physicalList.forEach(function(each) {
		physical += (fullName.match(new RegExp(each, "g")) || []).length;
	});
	intuitionList.forEach(function(each) {
		intuition += (fullName.match(new RegExp(each, "g")) || []).length;
	});
	mentalList.forEach(function(each) {
		mental += (fullName.match(new RegExp(each, "g")) || []).length;
	});

	return {
		emotional : emotional,
		physical  : physical, 
		intuition : intuition, 
		mental 	  : mental
	};
}

export const calculate_Effectiveness = (lifePath, expression, soulUrge) => {
	const x = Math.abs(expression - lifePath);
	const y = Math.abs(lifePath - soulUrge);
	const z = Math.abs(expression - soulUrge);
	return x + y + z;
}

export const calculate_companyValue = (companyName) => {
	const referenceMap = {
		'A' : 1, 'B' : 2, 'C' : 3, 'D' : 4, 'E' : 5, 'F' : 6, 'G' : 7, 'H' : 8, 'I' : 9,
		'J' : 1, 'K' : 2, 'L' : 3, 'M' : 4, 'N' : 5, 'O' : 6, 'P' : 7, 'Q' : 8, 'R' : 9, 
		'S' : 1, 'T' : 2, 'U' : 3, 'V' : 4, 'W' : 5, 'X' : 6, 'Y' : 7, 'Z' : 8,
		'1' : 1, '2' : 2, '3' : 3, '4' : 4, '5' : 5, '6' : 6, '7' : 7, '8' : 8, '9' : 9
	};
	let total = 0;
	companyName.split('').forEach(function(each) {
		if(referenceMap[each.toUpperCase()]) {
			total += referenceMap[each.toUpperCase()];
		}
	});
	return reduce(total);
}

export const returnMeaningOfNumber = (number) => {
	const referenceMap = {
		'1' : {
			positive : 'Independence/Self-sufficient',
			negative : 'Dependent/Selfish'
		},
		'2' :  {
			positive : 'Cooperation/Cooperative',
			negative : 'Reclusive'
		},
		'3' :  {
			positive : 'Joy/Fun-loving',
			negative : 'Superficial'
		},
		'4' :  {
			positive : 'Limitation/Resposible',
			negative : 'Dogmatic'
		},
		'5' :  {
			positive : 'Freedom/Change-oriented',
			negative : 'Restless'
		},
		'6' :  {
			positive : 'Responsibility/Generous',
			negative : 'Self-deprecating'
		},
		'7' :  {
			positive : 'Analysis/Introspective',
			negative : 'Self-centered'
		},
		'8' :  {
			positive : 'Material Satisfaction/Goal-oriented',
			negative : 'Materialistic'
		},
		'9' :  {
			positive : 'Selflessness/Giving',
			negative : 'Selfish'
		},
		'11' :  {
			positive : 'Illumination/Motivational',
			negative : 'Dreamer'
		},
		'22' :  {
			positive : 'Master Builder/Top Performer',
			negative : 'Self-serving'
		}
	};
	return referenceMap["" + number];
}
