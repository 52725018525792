import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import { lime } from "@material-ui/core/colors";
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import React from "react";
import NumerologyPaper from './NumerologyPaper';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3, 2),
    margin: 10,
  },
  chip: {
    margin: theme.spacing(1),
    backgroundColor: lime[700],
  },
}));

export default ({ core, others, temperament, effectiveness }) => {
  const classes = useStyles();

  return (
    <>
      {core && <NumerologyPaper
        title="Core"
        data={core}
      />}
      {others && <NumerologyPaper
        title="Others"
        data={others}
      />}
      {temperament && <NumerologyPaper
        title="Temperament"
        data={temperament}
      />}
      {effectiveness && (
        <Paper className={classes.paper}>
          <Chip
            avatar={<Avatar>{effectiveness}</Avatar>}
            label="Score"
            className={classes.chip}
          />
        </Paper>
      )}
    </>
  );
};
