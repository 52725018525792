import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Panel from './Panel';
import Person from './Person';
import Company from './Company';
import Address from './Address';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    marginBottom: 20,
  },
  subHeader: {
    fontSize: theme.typography.pxToRem(10),
    marginLeft: 10,
  }
}));

export default () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="static" color="primary" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit">
            Numerology
          </Typography>
          <Typography variant="h6" color="inherit" className={classes.subHeader}>
            by Ranartec LLC
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg">
        <Panel title="Person name based numerology" iconPath="/images/person.png">
          <Person />
        </Panel>
        <Panel title="Company name based numerology" iconPath="/images/company.png">
          <Company />
        </Panel>
        <Panel title="Address based numerology" iconPath="/images/address.png">
          <Address />
        </Panel>
      </Container>
    </div>
  );
}
