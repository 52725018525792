import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import { orange } from "@material-ui/core/colors";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState } from "react";

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: orange[300],
  },
  avatar: {
    width: 60,
    height: 60,
  }
}));

export default ({ title, iconPath, children }) => {
  const classes = useStyles();
  const [panelState, setPanelState] = useState(true);
  return (
    <ExpansionPanel expanded={panelState} onChange={() => setPanelState(!panelState)}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Chip
          avatar={<Avatar alt="Person name" src={iconPath} className={classes.avatar} />}
          label={title}
          className={classes.heading}
        />
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        {children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
