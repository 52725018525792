import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {
  validateLettersOnly,
  validateDOB,
  calculate_LifePath,
  calculate_Expression,
  calculate_SoulUrge,
  calculate_Growth,
  calculate_Maturity,
  calculate_Effectiveness,
  calculate_BirthDate,
  calculate_Challenge,
  calculate_Temperament,
} from "./engine";
import NumerologyResults from './PersonNumerologyResults';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: 0,
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
  root: {
    margin: 'auto',
  },
}));

export default () => {
  const classes = useStyles();
  const [values, setValues] = useState({
    fullName: '',
    dob: ' ',
  });
  const [errors, setErrors] = useState({});
  const [calculatedValues, setCalculatedValues] = useState({});

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
    setErrors({
      ...errors,
      [name]: false,
    });
  };
  const onReset = () => {
    setValues({
      fullName: '',
      dob: ' ',
    });
    setErrors({});
    setCalculatedValues({});
  };

  const onSubmit = () => {
    const err = {};
    if (!values.fullName || !values.fullName.trim() || !validateLettersOnly(values.fullName)) {
      err.fullName = true;
    }
    if (!values.dob || !values.dob.trim() || !validateDOB(values.dob)) {
      err.dob = true;
    }
    if (Object.keys(err).length === 0) {
      const lifePath = calculate_LifePath(values.dob);
      const expression = calculate_Expression(values.fullName);
      const soulUrge = calculate_SoulUrge(values.fullName);
      const birthDate = calculate_BirthDate(values.dob);
      const core = [{
        label: 'Life path',
        value: lifePath,
      }, {
        label: 'Expression',
        value: expression,
      }, {
        label: 'Soul urge',
        value: soulUrge,
      }, {
        label: 'Birth date',
        value: birthDate,
      }];
      calculatedValues.core = core;

      const growth = calculate_Growth(values.fullName.split(" ")[0]);
      const challenge = calculate_Challenge(values.dob);
      const maturity = calculate_Maturity(lifePath, expression);
      const others = [{
        label: 'Growth',
        value: growth,
      }, {
        label: 'Challenge',
        value: challenge,
      }, {
        label: 'Maturity',
        value: maturity,
      }];
      calculatedValues.others = others;

      const temperament = calculate_Temperament(values.fullName);
      calculatedValues.temperament = [{
        label: 'Emotional',
        value: temperament.emotional,
      }, {
        label: 'Physical',
        value: temperament.physical,
      }, {
        label: 'Intuition',
        value: temperament.intuition,
      }, {
        label: 'Mental',
        value: temperament.mental,
      }];

      calculatedValues.effectiveness = calculate_Effectiveness(lifePath, expression, soulUrge);
      setCalculatedValues({
        ...calculatedValues,
      });
    }
    setErrors(err);
  };

  return (
    <div className={classes.root}>
      <form className={classes.container} noValidate autoComplete="off">
        <TextField
          id="fullName"
          label="Full name"
          className={classes.textField}
          margin="normal"
          value={values.fullName}
          helperText="Including middle name"
          onChange={handleChange('fullName')}
          required
          variant="outlined"
          error={errors['fullName']}
        />
        <TextField
          id="dob"
          label="Date of birth"
          className={classes.textField}
          margin="normal"
          value={values.dob}
          onChange={handleChange('dob')}
          required
          variant="outlined"
          type="date"
          error={errors['dob']}
        />
        <div>
          <Button
            variant="contained"
            className={classes.button}
            onClick={onReset}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={onSubmit}
          >
            Calculate
          </Button>
        </div>
      </form>
      <NumerologyResults
        {...calculatedValues}
      />
    </div>
  );
};
