import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import { red } from "@material-ui/core/colors";
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from "react";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3, 2),
    margin: 10,
  },
  chip: {
    margin: theme.spacing(1),
    backgroundColor: red[400],
    color: 'white',
  },
}));

export default ({ title, data }) => {
  const classes = useStyles();
  if (!data || data.length <= 0) return null;
  return (
    <Paper className={classes.paper}>
      <Typography variant="h5" component="h3">
        {title}
      </Typography>
      {data.map(eData => (<Chip
        key={eData.label}
        avatar={<Avatar>{eData.value}</Avatar>}
        label={eData.label}
        className={classes.chip}
      />))}
    </Paper>
  );
};
